import { ApolloClient, createHttpLink, from, split, type DefaultOptions } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import settings from 'configurations/application';
import { generateXCorrelationId, X_APPLICATION_ID } from 'utils/headers';

import apolloCache from './cache';
import actualFetch from './fetch';
import authLink from './auth-link';
import errorLink from './error-link';

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

/**
 * Override of built-in fetch:
 * We need to attach X-Application-ID and X-Correlation-ID headers in a custom fetch function.
 * Using an ApolloLink would be cleaner, however, it doesn't work because:
 * - the ApolloLink with X- headers needs to come after the httpCallLinkSplit in from, but
 * - the httpCallLinkSplit is a "terminating" link, so it must be the last one in the chain.
 */
const customFetch: typeof fetch = (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
  if (init?.headers) {
    const { headers } = init;

    headers['X-Application-ID'] = X_APPLICATION_ID;
    headers['X-Correlation-ID'] = generateXCorrelationId();
  }

  return actualFetch(input, init);
};

const httpLink = createHttpLink({
  uri: `${settings.envVars.graphQLEndpoint}`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  fetch: customFetch,
});

const batchHttpLink = new BatchHttpLink({
  uri: `${settings.envVars.graphQLEndpoint}`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  fetch: customFetch,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

const httpCallLinkSplit = split(
  (operation) => operation.getContext().preventBatching === true,
  httpLink,
  batchHttpLink
);

const client = new ApolloClient({
  link: from([errorLink, authLink, httpCallLinkSplit]),
  cache: apolloCache,
  defaultOptions,
});

export default client;
